var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAppIdBySlug } from "../../apis/app";
import Divider from "@mui/material/Divider";
export var SampleRequest = function () {
    var _a = useState({ id: "" }), appId = _a[0], setAppId = _a[1];
    var getAppId = useCallback(function () {
        getAppIdBySlug("msbrenda")
            .then(function (response) {
            setAppId(response);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, []);
    useEffect(function () {
        if (!appId.id)
            getAppId();
    }, [appId, getAppId]);
    return (_jsx(Box, { children: _jsx(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Requisi\u00E7\u00E3o" })), _jsx("hr", {}), _jsx(Typography, __assign({ component: "div" }, { children: _jsxs(Box, __assign({ fontWeight: "bold" }, { children: [_jsxs(Typography, __assign({ align: "center", gutterBottom: true }, { children: ["Retornando o ID do app atrav\u00E9s do Slug:", _jsx(Typography, __assign({ sx: { fontStyle: "italic" } }, { children: "msbrenda" }))] })), _jsx(Divider, {}), _jsxs(Box, __assign({ fontWeight: "normal", display: "inline" }, { children: [_jsx(Typography, __assign({ sx: { mr: 1, display: "inline" }, variant: "subtitle1", fontWeight: "bold", color: "text.primary" }, { children: "ID:" })), _jsx(Typography, __assign({ variant: "subtitle2", color: "text.secondary", sx: { display: "inline" } }, { children: appId.id }))] }))] })) }))] })) })) })) }));
};
